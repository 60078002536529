// CUSTOM STYLE

// Articles
.post-title, .recent-articles {
  a {
    white-space: break-spaces;
  }
}

.content-article {
  color: #434445;

  >p, >div {
    margin-bottom: 16px;
  }
  img {
    float: left;
    margin: 0 24px 24px 0;
  }
  .alignleft {
    float: left;
    margin: 0 24px 24px 0;
  }
  .alignright {
    float: right;
    margin: 0 0 24px 24px;
  }
  .wp-caption-text {
    color: #868686;
  }
}

// Backgrounds
.bg-default-dark {
  background-color: #20658d;
}

.bg-default-green {
  background: #66cc66;
  color: #ffffff;

  h1, h2, h3, h4, h5, h6, p, a, span {
    color: #ffffff;
  }
}

// Box skin
.box-skin-green {
  background: #66cc66;
  color: #ffffff;
  border-radius: 5px;
  padding: 34px 30px;
  transition: 0.2s ease-in;

  h1, h2, h3, h4, h5, h6, p, a, span {
    color: #ffffff;
  }
}

// Breadcrumps
.breadcrumbs-custom {
  padding: 0;

  .background {
    background: linear-gradient(180deg, rgba(54, 168, 235, 0.7), rgba(54, 168, 235, 0) 100%),
                linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%);

    .container {
      padding: 230px 0 41px;
    }
  }
}

// Buttons
.btn-youtube {
  background-color: #cc0000;
  color: #fff;

  &:hover {
    opacity: 0.8;
    color: #fff;
  }
}

// Cookie consent
.cookie-consent-container {
  display: none;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

// Context dark
.context-dark {
  a {
    &:not(.icon, .btn) {
      color: #fff;

      &:hover {
        color: #9fddff;
      }
    }
  }
}

// Footer
footer {
  background: #304356;

  .rights {
    text-align: left !important;
  }
}

// Form
.form-validation {
  font-size: 13px;
}

.form-output, #form-output {
  background-color: #304356;
  font-size: 16px;
  margin-top: -54px;
}

// Google Maps
.google-map {
  height: 500px;

  &.mini {
    height: 400px;
  }
}

// iFrame
iframe {
  width: 100%;
}

// Josip
.thumbnail-josip {
  &.odd {
    .thumbnail-desc {
      background: #20658d;
    }
  }

  &:hover {
    &.no-animation {
      .thumbnail-desc {
        transform: none;
      }
    }
  }

  .thumbnail-desc {
    p {
      opacity: 0.8;
    }
  }
}

// Link social

.link-social {
  display: flex;
  flex-direction: column;
  align-items: center;

  .linkedin {
    background-color: #0E7EB5;
    color: #fff;
  }

  .facebook {
    background-color: #1877f2;
    color: #fff;
  }
  .youtube {
    background-color: #cc0000;
    color: #fff;
  }
  .instagram {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    color: #fff;
  }

  .icon {
    font-size: 70px;
    transition: all .5s;
  }

  &:hover {
    .icon {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }
}

// Navbar
.rd-navbar-classic.rd-navbar-static .rd-navbar-aside-outer {
  background: rgba(0,0,0,0.5);

  .rd-navbar-aside {
    padding: 16px 0;

    .contact-info + .contact-info {
      margin-left: 50px;
    }
  }
}

// Parallax container
.parallax-container {
  &.bg-gradient {
    .parallax-content {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%);
    }
  }
}

// Progress circle bar
.progress-circle-fg {
  stroke: #36a8eb;
}

// Section image aside
.section-image-aside-img {
  background-position: center;
}

// Slider
.swiper-slider {
  &.bg-gradient {
    .swiper-slide {
      height: 720px;

      .swiper-caption {
        background: linear-gradient(180deg, rgba(54, 168, 235, 0.7), rgba(54, 168, 235, 0) 100%),
                    linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0) 100%);
      }
    }
  }
}

// Table
table.table-custom-dark {
  background: none;

  tr {
    &:hover {
      td {
        color: #ffffff;
        background-color: rgba(255,255,255,0.1);
      }
    }

    td {
      color: #ffffff;
      background: none;
    }
  }
}

/**/

// TABLET

@media only screen and (max-width: 1199px) {

  // Breadcrumbs
  .breadcrumbs-custom {
    .background {
      .container {
        padding: 100px 15px 41px;
      }
    }
  }

  // Headings
  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
  }

  // Navbar
  .rd-navbar-fixed {
    .rd-navbar-panel {
      background: #304356;
    }

    .rd-navbar-nav {
      .rd-nav-item {
        .rd-nav-link {
          font-size: 16px;
        }
      }

      .rd-navbar-megamenu {
        .rd-megamenu-title {
          color: #304356;
        }
      }

      .rd-megamenu-list, .rd-navbar-dropdown {
        background: #f6f6f6;

        >li >a {
          font-size: 15px;
        }
      }
    }
  }

  // Slider
  .swiper-slider {
    &.bg-gradient {
      .swiper-slide {
        height: 600px;

        .swiper-caption {
          h2 {
            font-size: 48px;
            line-height: 1;
          }
        }
      }
    }
  }
}

/**/

// MOBILE

@media only screen and (max-width: 991px) {

  // Slider
  .swiper-slider {
    &.bg-gradient {
      .swiper-slide {
        height: 500px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {

  // Section image aside
  .section-image-aside-img {
    position: relative;
    width: 100%;
    height: 240px;
    right: 0 !important;
    left: 0 !important;
  }
}

/**/